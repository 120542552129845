// SIDEBAR
function menu_sidebar_effects() {

  // Sidebar links
  $('.sidebar .sidebar-menu li a').on('click', function () {
    const $this = $(this);

    if ($this.parent().hasClass('open')) {
      $this
        .parent()
        .children('.dropdown-menu')
        .slideUp(200, () => {
          $this.parent().removeClass('open');
        });
    } else {
      $this
        .parent()
        .parent()
        .children('li.open')
        .children('.dropdown-menu')
        .slideUp(200);

      $this
        .parent()
        .parent()
        .children('li.open')
        .children('a')
        .removeClass('open');

      $this
        .parent()
        .parent()
        .children('li.open')
        .removeClass('open');

      $this
        .parent()
        .children('.dropdown-menu')
        .slideDown(200, () => {
          $this.parent().addClass('open');
        });
    }
  });

  // Sidebar Activity Class
  const sidebarLinks = $('.sidebar').find('.sidebar-link');

  sidebarLinks
    .each((index, el) => {
      $(el).removeClass('active');
    })
    .filter(function () {
      const href = $(this).attr('href');
      const pattern = href[0] === '/' ? href.substr(1) : href;
      return pattern === (window.location.pathname).substr(1);
    })
    .addClass('active');

  // ٍSidebar Toggle
  $('.sidebar-toggle').on('click', e => {
    $('.app').toggleClass('is-collapsed');
    e.preventDefault();
  });

  $('.sidebar-link').on('click', e => {
    if ($('.app').hasClass('is-collapsed') && screen.width <= 990) {
      $('.app').toggleClass('is-collapsed');
    }
    e.preventDefault();
  });

  $('.main-content').on('click', e => {
    if ($('.app').hasClass('is-collapsed') && screen.width <= 990) {
      $('.app').toggleClass('is-collapsed');
    }
    e.preventDefault();
  });


  /**
   * Wait untill sidebar fully toggled (animated in/out)
   * then trigger window resize event in order to recalculate
   * masonry layout widths and gutters.
   */
  /*$('#sidebar-toggle').click(e => {
    e.preventDefault();
    setTimeout(() => {
      window.dispatchEvent(window.EVENT);
    }, 300);
  });*/

}


//Tabla del apartado consumos
var dataConsumosTablaObj = null;

function dataConsumosTabla(datos) {
  var dataArr = datos.map(function (datos) {
    var formato = '';
    if (datos['fechacambiocomercializadora'] > 0) {
      var fecha = new Date(datos['fechacambiocomercializadora']);
      formato = fecha.getDate() + "/" + fecha.getMonth() + "/" + fecha.getFullYear();
    }
    return [datos['cups'], datos['municipio'], datos['tarifa'], datos['consumo'], datos['ahorro'],
      datos['comercializadora'], datos['cnae'], formato];
  });

  if (dataConsumosTablaObj != null) {
    dataConsumosTablaObj.destroy();
  }

  dataConsumosTablaObj = $('#dataTable').DataTable({
    data: dataArr,
    columns: [
      {
        title: "Informacion",
        render: function (data, type, row, meta) {
          if (type === 'display') {
            data = '<a class="btn btn-success" href="/#/panel/cups/info/' + data + '" target="_blank"><i class="ti-search"></i> CUPS </a>';
          }
          return data;
        }
      },
      {title: "Municipio"},
      {title: "Tarifa"},
      {title: "Consumo Anual"},
      {title: "Ahorro"},
      {title: "Comercializadora"},
      {title: "CNAE"},
      {title: "Fecha Comercializadora"},
    ]
  });
}


//Tabla del apartado consumos
var dataReactivaTablaObj = null;

function dataReactivaTabla(datos) {
  var dataArr = datos.map(function (datos) {
    return [datos['cups'], datos['municipio'], datos['tarifa'], datos['comercializadora'], datos['cnae'], datos['reactiva']];
  });

  if (dataReactivaTablaObj != null) {
    dataReactivaTablaObj.destroy();
  }

  dataReactivaTablaObj = $('#dataTable').DataTable({
    data: dataArr,
    columns: [
      {
        title: "Informacion",
        render: function (data, type, row, meta) {
          if (type === 'display') {
            data = '<a class="btn btn-success" href="/#/panel/cups/info/' + data + '" target="_blank"><i class="ti-search"></i> CUPS </a>';
          }
          return data;
        }
      },
      {title: "Municipio"},
      {title: "Tarifa"},
      {title: "Comercializadora"},
      {title: "CNAE"},
      {title: "Reactiva"},
    ]
  });
}

//Tabla del apartado consumos
var dataReactivaTablaObjCif = null;

function dataCifs(datos) {
  var dataArr = datos.map(function (datos) {

    return [replaceNull(datos['cups']), replaceNull(datos['codigoprovinciaps']), replaceNull(datos['municipiops']), replaceNull(datos['codigotarifaatrenvigor'])
      , replaceNull(datos['consumoanual']), replaceNull(datos['ahorro']), replaceNull(datos['comercializadora'])];
  });

  if (dataReactivaTablaObj != null) {
    dataReactivaTablaObj.destroy();
  }

  dataReactivaTablaObj = $('#dataTableCif').DataTable({
    data: dataArr,
    columns: [
      {
        title: "Informacion",
        render: function (data, type, row, meta) {
          if (type === 'display') {
            data = '<a class="btn btn-success" href="/#/panel/cups/info/' + data + '" target="_blank"><i class="ti-search"></i> CUPS </a>';
          }
          return data;
        }
      },
      {title: "Provincia"},
      {title: "Municipio"},
      {title: "Tarifa"},
      {title: "Consumo"},
      {title: "Ahorro"},
      {title: "Comercializadora"},
    ]
  });
}


//Tabla comercializadora
var dataComercializadora = null;

function dataComercializadoraTabla(datos) {
  var dataArr = datos.map(function (datos) {
    return [replaceNull(datos['cups']), replaceNull(datos['codigoprovinciaps']), replaceNull(datos['municipiops']),
      replaceNull(datos['codigotarifaatrenvigor']), replaceNull(datos['consumoanual']), replaceNull(datos['reactivaanual']), replaceNull(datos['consumoanualdias'])
      , replaceNull(datos['ahorro']), replaceNull(datos['cnae'])];
  });

  if (dataReactivaTablaObj != null) {
    dataReactivaTablaObj.destroy();
  }

  dataReactivaTablaObj = $('#dataComercializadora').DataTable({
    data: dataArr,
    columns: [
      {
        title: "Informacion",
        render: function (data, type, row, meta) {
          if (type === 'display') {
            data = '<a class="btn btn-success" href="/#/panel/cups/info/' + data + '" target="_blank"><i class="ti-search"></i> CUPS </a>';
          }
          return data;
        }
      },
      {title: "Provincia"},
      {title: "Municipio"},
      {title: "Tarifa"},
      {title: "Consumo Anual"},
      {title: "Reactiva Anual"},
      {title: "Dias"},
      {title: "Ahorro"},
      {title: "Cnae"},
    ]
  });
}



//Barra
function generarBarra(consumosp1, contratadap1, optimap1, id) {

  let labels = [];
  for (let i = 0;i < consumosp1.length; i++) {
    labels.push('');
  }

  Highcharts.chart(id, {
    chart: {
      type: 'column'
    },
    title: {
      text: ''
    },
    credits: {
      enabled: false
    },
    hover:{
      enabled: false
    },
    xAxis: {
      categories: labels,
      crosshair: true
    },
    tooltip: { enabled: false },
    yAxis: {
      min: 0,
      title: {
        text: ''
      },
      plotLines: [{
        color: '#65d656',
        width: 4,
        zIndex: 10000,
        value: optimap1
      },{
        color: '#eeb431',
        width: 4,
        zIndex: 9999,
        value: contratadap1
      }]
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0
      },
      series: {
        enableMouseTracking: false
      }
    },
    series: [{
      name: id,
      color: '#d8dbde',
      data: consumosp1

    }, {
      name: '',showInLegend: false,
      type: 'scatter',
      marker: {
        enabled: false
      },
      data: [optimap1]
    },{
      name: '',showInLegend: false,
      type: 'scatter',
      marker: {
        enabled: false
      },
      data: [contratadap1]
    }
    ]
  });
}


function replaceNull(data){
  if(data == null || data === undefined){
      data = "";
  }
  return data;
}
